import moment from "moment";
import { Residence } from "../Entity/businesses/residence";
import { Simulation } from "../sim";
import { Citizen } from "../Entity/citizen";


export default function rent_system(sim : Simulation)
{
    const { world, sim_time, bank } = sim;

    const day = moment.duration(sim_time.time * 1000).asDays() % 30;
    const hour = moment.duration(sim_time.time * 1000).asHours() % 24;

    //if its the first of the month
    const firstOfTheMonth : boolean = day === 0 && hour === 0;

    const entities = world.with("citizen");

    const [apartment_untyped] = world.with("residence");

    const apartment = apartment_untyped as Residence;

    //if rent is due, pay it
    for (const entity of entities) {
        if(firstOfTheMonth){
            apartment.collectRent(entity as Citizen, sim_time, bank);
        }
    }
    
}