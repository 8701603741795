import { useEffect, useState } from "react";

export function useKeyDown(key : string) : boolean
{
    const [keyHeld, setKeyHeld] = useState(false);
  
    useEffect(() => {
        const downHandler = (e: KeyboardEvent) => {
            e.preventDefault();

            if (e.key === key) {
                setKeyHeld(true);
            }
        };
        const upHandler = (e: KeyboardEvent) => {
            e.preventDefault();
            if (e.key === key) {
                setKeyHeld(false);
            }
        };
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [key]);


    return keyHeld;
}