import { Config, adjectives, names, uniqueNamesGenerator } from "unique-names-generator";


const customConfig: Config = {
    dictionaries: [adjectives, names],
    length: 2,
    separator: ' ',
    style: 'capital',
};

export class HumanName extends Object
{
    firstName: string;
    lastName: string;

    constructor()
    {
        super();
        const name = uniqueNamesGenerator(customConfig);

        this.firstName = name.split(" ")[0];
        this.lastName = name.split(" ")[1];
    }

    toString(): string {
        return `${this.firstName} ${this.lastName}`;
    }
}