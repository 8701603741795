import { CheckingAccount, CheckingAccountComponent } from "../../Component/citizen/checkingAccount";
import { Inventory } from "../../Component/items/inventory";
import { TransactionParticipant } from "../../System/banking/transaction";
import { Bank } from "../../System/banking/bank";
import { Card } from "../../UI/Card";
import { BoxGeometry, Mesh, MeshBasicMaterial } from "three";
import { Random } from "random";

export enum BusinessType {
    Restaurant = "Restaurant",
    ApartmentComplex = "Apartment Complex",
}

export class Business extends Object{
        
    business: boolean = true;
    name: string;
    type: BusinessType;

    checkingAccount: CheckingAccount;
    inventory: Inventory;
    mesh: Mesh;

    constructor(name: string, type: BusinessType, random: Random) {
        super();

        this.name = name;
        this.type = type;


        this.checkingAccount = new CheckingAccount(100000);
        this.inventory = new Inventory();


        const geometry = new BoxGeometry( 0.7, 1, 0.7 );
        const material = new MeshBasicMaterial({
            color: "#404040",
        });

        //TODO: finish business mesh generation and add commute system for citizens
        this.mesh = new Mesh( geometry, material );
        this.mesh.position.set(random.int(-5, 5) + 0.5, 0.5, random.int(-5, 5) + 0.5);
    }

    processPurchase(item: string, quantity: number, buyer : TransactionParticipant, posixTime: number, bank : Bank) {
        bank.performTransactionWithInventory(
            item,
            quantity,
            buyer,
            this,
            posixTime,
            true
        );
    }


} 

export function BusinessComponent(props : {business: Business}) {
    return (
        <div className='m-2' key={props.business.name}>
            <Card title={props.business.name} subtitle={props.business.type}>
                <CheckingAccountComponent checkingAccount={props.business.checkingAccount}/>
            </Card>
        </div>
    );
}