import { Government } from "../Entity/government";
import { Bank } from "../System/banking/bank";
import { Simulation } from "../sim";
import ChronoDisplay from "./ChronoDisplay";
import { MetricDisplay, SIUnit } from "./Units";


export default function Footer(props: {duration_raw: number, government: Government, bank: Bank, sim : Simulation})
{
  return (
    <div className='sticky bottom-0 left-full px-8 py-6 flex w-max flex-col items-end'>
        <div className='flex items-center bg-neutral-700'>
            <CivilizationOSLogo />
            <ChronoDisplay duration_raw={props.duration_raw} sim={props.sim} />
        </div>
        
    </div>
  );
}

function CivilizationOSLogo(){
  return(
    <div className='h-16 w-16 -rotate-90 relative select-none cursor-default'>
      <div className=' bg-neutral-700  h-4 w-16'>
        <p className='text-xs text-white font-bold font-mono text-center'>HOSTILE</p>
        <div className='h-1 px-1 heropattern-texture-white bg-neutral-700'></div>
      </div>
    </div>
  )
}