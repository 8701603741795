import { World } from "miniplex";
import { Citizen } from "../Entity/citizen";
import { Time } from "../time";
import { Simulation } from "../sim";
import { Water } from "../Component/citizen/water";

export default function hydration_system(sim : Simulation)
{
    const { world, sim_time } = sim;


    const hydration_entities = world.with("water");

    for(let entity of hydration_entities){
        const { water } = entity;

        (water as Water).update(sim_time);
    }
}