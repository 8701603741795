import { Random } from "random";
import { clamp } from "../../util/math";
import { ProgressDisplay, SlimProgessBar } from "../../UI/ProgressBar";
import { SIUnit, UnitsDisplay } from "../../UI/Units";
import { Time } from "../../time";
import { StatDropDown } from "../../UI/InfoCard";

const hour = 3600; //an hour in seconds


//https://pubmed.ncbi.nlm.nih.gov/19447412/
const DAYTIME_WATER_ABSORPTION_RATE = 0.083/hour;
const NIGHTTIME_WATER_ABSORPTION_RATE = 0.048/hour;


//https://www.geisinger.org/health-and-wellness/wellness-articles/2023/02/22/18/15/how-much-water-to-drink-per-day
const WATER_NEEDED_PER_DAY_MEN = 3.7; //liters
const WATER_NEEDED_PER_DAY_WOMEN = 2.7;//liters

//Aprox 150ml per hour
const WATER_NEEDED_PER_HOUR_MEN = WATER_NEEDED_PER_DAY_MEN / 24;
const WATER_NEEDED_PER_HOUR_WOMEN = WATER_NEEDED_PER_DAY_WOMEN / 24;

//average size of the bladder is between 300 and 400 mL
//https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3206217/#:~:text=Normal%20functional%20bladder%20capacity%20in,ml%20(58%2C59).
const AVERAGE_BLADDER_SIZE = 0.350; //liters

const ONE_CUP_OF_WATER = 0.236588; //liters

export class Water extends Object
{
    bladder : number;
    hydration : number;
    totalWaterUsage : number;
    
    //The max amount of water someone would have in their stomache
    HYDRATION_MAX: number;
    //The max amount of water someone would have in their bladder
    BLADDER_MAX: number;

    WATER_ABSORPTION_RATE: number;

    HYDRATION_THRESHOLD: number = 0.5;


    constructor(random : Random)
    {
        super();

        this.bladder = 0.0;
        this.hydration = 0.0;
        this.totalWaterUsage = 0.0;

        
        this.HYDRATION_MAX = random.normal(WATER_NEEDED_PER_HOUR_MEN, 0.020)();

        this.WATER_ABSORPTION_RATE = random.normal(DAYTIME_WATER_ABSORPTION_RATE, 0.010/hour)();

        this.BLADDER_MAX = random.normal(AVERAGE_BLADDER_SIZE, 0.020)();
    }

    public update(time : Time) {

        //if the stomache isn't full of water and we don't need to piss, drink some water
        //TODO: instead of performing the action we will update the priority
        if(this.needsToDrink() && !this.needsToPiss()) this.drink(ONE_CUP_OF_WATER);

        //if the stomache has water in it, absorb it into the bladder 
        if(this.hydration > 0) this.absorbIntoBladder(time.deltaTime);

        //if the bladder is full, piss
        //TODO: instead of performing the action we will update the priority
        if(this.needsToPiss()) this.piss();
    }

    absorbIntoBladder(deltaTime: number) {
        this.hydration -= deltaTime * this.WATER_ABSORPTION_RATE;
        this.bladder += deltaTime * this.WATER_ABSORPTION_RATE;
    }

    drink(amount: number) {
        this.hydration += amount;
        this.totalWaterUsage += amount;
    }

    piss() {
        this.bladder = 0;
    }

    needsToDrink() : boolean {
        //
        return this.hydration < (this.HYDRATION_THRESHOLD * this.HYDRATION_MAX);
    }

    needsToPiss() : boolean {
        return this.bladder >= this.BLADDER_MAX;
    }

}

export function WaterComponent(props : {water: Water}) {
    return (
        <StatDropDown title='Water'>
            <ProgressDisplay label='Hydration' color='blue' value={props.water.hydration} max={props.water.HYDRATION_MAX} units={SIUnit.Liters}/>
            <ProgressDisplay label='Bladder' color='yellow' value={props.water.bladder} max={props.water.BLADDER_MAX} units={SIUnit.Liters}/>
            <div className='flex flex-row'>
                <p className='text-gray-600 text-xs'>Total Water Consumption</p>
                <UnitsDisplay value={props.water.totalWaterUsage} unit={SIUnit.Liters}/>
            </div> 
        </StatDropDown>
    );
}