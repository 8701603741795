import { randomUUID } from "crypto";

export class Inventory extends Object {
    inventory: Map<string, Item>;

    constructor() {
        super();
        this.inventory = new Map();
    }

    add(name: string, quantity: number, price?: number) {
        //check if the item is already in the inventory
        const item = this.inventory.get(name);

        if (item !== undefined) {
            item.quantity += quantity;
        }
        else {
            this.inventory.set(name, {
                name: name,
                quantity: quantity,
                price: price
            });
        }

        
    }

    remove(name: string, quantity: number) {
        const item = this.inventory.get(name);
        if (item !== undefined) {
            item.quantity -= quantity;
        }
    }
}

//FOR now all businesses will have their own items AT SOME POINT make an item DB so all the IDs are shared for the same item
export interface Item {
    name: string;
    price?: number;
    quantity: number;
}