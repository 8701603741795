import { Occupation, OccupationComponent } from "../Component/citizen/occupation";
import { CheckingAccount, CheckingAccountComponent } from "../Component/citizen/checkingAccount";
import { HumanName } from "../Component/citizen/name";
import { Inventory } from "../Component/items/inventory";
import { World } from "miniplex";
import { WaterComponent, Water } from "../Component/citizen/water";
import { Random } from "random";
import { Card } from "../UI/Card";
import { BoxGeometry, Mesh, MeshBasicMaterial, MeshNormalMaterial, Object3D, Vector3 } from "three";
import { Nav } from "../Component/nav";


export class Citizen extends Object {

    citizen : boolean = true;
    occupation: Occupation
    checkingAccount: CheckingAccount;
    name: HumanName;
    inventory: Inventory;
    water: Water;
    mesh: Mesh;
    nav: Nav;
    transform: Object3D;

    constructor(world : World, random: Random)
    {
        super();
        
        this.name = new HumanName(); 
        this.occupation = new Occupation(world); 
        this.checkingAccount = new CheckingAccount(); 
        this.inventory = new Inventory(); 
        this.water = new Water(random); 

        const geometry = new BoxGeometry( 0.1, 0.1, 0.1 );
        const material = new MeshBasicMaterial({
            color: "#9e9e9e",
        });
    
        this.mesh = new Mesh( geometry, material );
        this.mesh.position.x = random.int(0, 3);
        this.mesh.position.y = 0;
        this.mesh.position.z = random.int(0, 3);

        this.nav = new Nav(2);
        this.transform = new Object3D();
        this.transform.add(this.mesh);

        this.nav.setTarget(new Vector3(5, 0, 7));
    }
}

export function CitizenMesh(props : {citizen: Citizen}) {
}

export function CitizenComponent(props : {citizen: Citizen}) {

    return (
        <div className='m-1' key={props.citizen.name.toString()}>
            <Card title={props.citizen.name.toString()} subtitle={props.citizen.occupation.name}>
                <div className="flex w-full flex-wrap p-0.5 bg-neutral-100">
                    <OccupationComponent occupation={props.citizen.occupation}/>
                    <CheckingAccountComponent checkingAccount={props.citizen.checkingAccount}/>
                    <WaterComponent water={props.citizen.water}/>
                </div>
            </Card>
        </div>
    );
}