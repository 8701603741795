import { PlayIcon, PauseIcon, ForwardIcon, ArrowPathRoundedSquareIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import { Simulation, SimulationSpeed } from "../sim";
import { useState } from "react";

export default function ChronoDisplay(props : { duration_raw : number, sim: Simulation }) {

  const [is_paused, setIsPaused] = useState<boolean>(props.sim.isPaused());

  const duration = moment.duration(props.duration_raw * 1000);

  const days = duration.asDays().toFixed(0).padStart(2,'0');
  const hours = duration.hours().toString().padStart(2,'0');
  const minutes = duration.minutes().toString().padStart(2,'0');

  const handle_pause_play_click = () => {
    if(is_paused){
      props.sim.resume();
    }else{
      props.sim.pause();
    }
    setIsPaused(!is_paused);
  }

  const handle_speed_click = () => {
    if(props.sim.getSpeed() === SimulationSpeed.Standard){
      props.sim.setSpeed(SimulationSpeed.Fast);
    }else{
      props.sim.setSpeed(SimulationSpeed.Standard);
    }
  }

  

  return(
      <div className='flex flex-row items-center'> 
        <div className='grid grid-cols-3 space-x-0.5 -ml-10'>
          <ChronoLabel label='days' value={days} most_significant_digit/>
          <ChronoLabel label='hours' value={hours}/>
          <ChronoLabel label='minutes' value={minutes}/>
        </div>
        <div className="flex flex-col space-y-1 bg-neutral-700 pl-1 h-full items-end justify-items-end justify-end">
            <div className="cursor-pointer hover:bg-neutral-400 p-0.5" onClick={handle_pause_play_click}>
              {!is_paused && <PauseIcon className="h-4 w-4 text-white " aria-hidden="true" />}
              {is_paused && <PlayIcon className="h-4 w-4 text-white hover:bg-neutral-400" aria-hidden="true" />}
            </div>
          <div className="cursor-pointer hover:bg-neutral-400 p-0.5" onClick={handle_speed_click}>
            <p className="font-mono text-white text-xs">{props.sim.getSpeed()}x</p>
          </div>
          <div className="cursor-pointer hover:bg-neutral-400 p-0.5" onClick={()=>{}}>
            <ArrowPathRoundedSquareIcon className="h-4 w-4 text-white hover:bg-neutral-400" aria-hidden="true" />
          </div>
          
        </div>
      </div>
  )
}

function ChronoLabel(props : { label : string, value: string, most_significant_digit?: boolean})
{
  return (
    <div className='flex flex-col font-mono border select-none cursor-default'>
      <p className={`text-2xl font-semibold text-white bg-neutral-700 text-center`}>{props.value}</p>
      <div className={` flex justify-end`}>
        <p className='text-xs text-neutral-300 font-semibold px-1'>{props.label}</p>
      </div>
    </div>
  )
}