import { Fragment, useEffect, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { Simulation } from '../sim'
import { Citizen } from '../Entity/citizen';

export default function CitizenChoicePrompt(props: { title : string, sim : Simulation }) {
    const [open, setOpen] = useState(true);

    const cancelButtonRef = useRef(null);

    //gen 3 random citizens
    const rngCitizenChoices = Array.from([0,1,2], () => props.sim.getNewCitizen());

    //pause the sim on open
    useEffect(() => {
        if(open) props.sim.pause();
        else props.sim.resume();
    },[open, props.sim]);

    return (
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full  items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden bg-neutral-400 text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
                    <div className="sm:flex sm:items-start flex-col w-full relative">
                        <div className='w-full bg-neutral-700 flex flex-row justify-between border-b-2 border-neutral-600'>
                            <div className="flex h-12 w-12 flex-shrink-0 items-center justify-center bg-neutral-600 p-2">
                                <ExclamationTriangleIcon className="h-10 w-10 text-neutral-100" aria-hidden="true" />
                            </div> 
                            <Dialog.Title as="h3" className="  text-xl leading-6 text-neutral-100 uppercase font-mono font-bold p-1 px-4 flex items-center ">
                                {props.title}   
                            </Dialog.Title>
                        </div>
                        <div className='p-1 w-full relative'>
                        {
                            rngCitizenChoices.map((c) => <CitizenChoiceCard citizen={c} setOpen={setOpen} sim={props.sim} />)
                        }
                        </div>
                        <div className='h-4 w-full heropattern-texture-neutral-700  mt-1 filter drop-shadow-md' />
                    </div>
                </Dialog.Panel>
                </Transition.Child>
            </div>
            </div>
        </Dialog>
        </Transition.Root>
    );
}


function CitizenChoiceCard(props : {citizen : Citizen, sim : Simulation, setOpen : (open : boolean) => void}) {

    const photo_name = props.citizen.occupation.name.toString();
    const photo_string = `/assets/occupations/male_20s/${photo_name}.png`;

    const onclick = () => {
        props.sim.addCitizen(props.citizen);
        props.setOpen(false);
    }

    return (
        <div className='px-2 py-1 '>
            <div className='flex flex-row filter'>
                <div className='bg-neutral-200 text-xs py-0.5 px-2 text-neutral-600 font-semibold' >{props.citizen.name.toString()}</div>
            </div>
            <div>
                <div className="p-1 bg-neutral-200">
                    <div className=' w-full cursor-pointer bg-white hover:bg-neutral-300 flex flex-row filter drop-shadow border-r-4 border-neutral-400' onClick={onclick}>
                        <div className='heropattern-texture-neutral-500 relative '>
                            <img src={photo_string} alt={photo_string} className='w-20 h-20'/>
                        </div>
                        <div className="h-20 p-2 flex items-start flex-col">
                            <p className='text-neutral-700 font-bold '>{props.citizen.occupation.name}</p>
                            <div className=''>
                                <p className='text-neutral-600 text-xs font-semibold'>Median Hourly Income</p>
                                <p className='text-blue-500 text-sm font-semibold font-mono'>${props.citizen.occupation.median}</p>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-row filter drop-shadow py-0.5'>
                        <div className='bg-neutral-500 hover:bg-neutral-300 text-xs py-0.5 px-1 w-max text-neutral-100 font-semibold cursor-pointer' >{props.citizen.occupation.name}</div>
                    </div>
                </div>
            </div>
        </div>
    );
}
