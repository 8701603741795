import { Disclosure } from '@headlessui/react'

export function StatDropDown(props : { title: string, children: any }) {
    return (
        <div className='m-0.5 w-max'>
            <Disclosure>
                {({ open }) => (
                <>
                    <Disclosure.Button className="flex items-center w-max h-max px-2  bg-neutral-200 gap-x-1 hover:bg-neutral-300">
                        <span className='text-sm leading-6 text-neutral-600'>
                            {props.title.toLowerCase()}
                        </span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="p-0.5 bg-neutral-100 w-40">
                        {props.children}
                    </Disclosure.Panel>
                </>
                )}
            </Disclosure>
        </div>
    );
}