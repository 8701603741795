import { StatDropDown } from "../../UI/InfoCard";
import { CurrencyDisplay } from "../../UI/Units";
import { DiffDisplay } from "../../util/diff";

export class CheckingAccount extends Object {
    balance: number;
    diff_this_time_step: number;

    constructor(startingBalance?: number) {
        super();
        this.balance = startingBalance ?? 2000;
        this.diff_this_time_step = 0;
    }
}

export function CheckingAccountComponent(props : {checkingAccount: CheckingAccount}) {
    return(
        <StatDropDown title='Bank Account' >
            <p className='text-gray-600 text-xs'>Checking Account</p>
            <div className='flex flex-row'>
                <CurrencyDisplay value={props.checkingAccount.balance} />
                <DiffDisplay diff={props.checkingAccount.diff_this_time_step}/>
            </div>
        </StatDropDown>
    );
}