import { CurrencyDisplay } from "../../UI/Units";

export class FederalReserve extends Object {

    balance: number;

    constructor() {
        super();
        this.balance = 100000;
    }

    deposit(amount: number)
    {
        this.balance += amount;
    }

}

export function FederalReserveComponent(props: { federalReserve: FederalReserve }) {
    return (
        <div className='m-2' key={"government"}>
            <div className='p-2'>
                <div className=''>
                    <p className='text-gray-700 font-semibold '>Federal Reserve Balance</p>
                    <CurrencyDisplay value={props.federalReserve.balance} />
                </div>
            </div>
        </div> 
    )
}