import { CheckingAccount } from "../../Component/citizen/checkingAccount";
import { HumanName } from "../../Component/citizen/name";
import { Inventory } from "../../Component/items/inventory";

export interface TransactionParticipant {
    checkingAccount: CheckingAccount;
    inventory: Inventory;
    name?: HumanName | string;
}

export class TransactionError extends Error {
    

    public static validateTransaction(item: string, price: number, quantity: number, buyer: TransactionParticipant, seller: TransactionParticipant) {
        if (price < 0) {
            throw new TransactionError("Price must be positive");
        }

        if (quantity < 0) {
            throw new TransactionError("Quantity must be positive");
        }

        if (buyer.checkingAccount.balance < price) {
            throw new TransactionError(`Buyer : ${ buyer.name } does not have enough money to buy ${item} from ${seller.name}`);
        }

        const seller_inventory = seller.inventory.inventory.get(item);

        if (seller_inventory === undefined || seller_inventory.quantity < quantity) {
            throw new TransactionError(`Seller : ${ seller.name } does not have enough ${item} to sell to ${buyer.name}`);
        }
    }
}

export const DefaultParticipant : TransactionParticipant = {
    checkingAccount: new CheckingAccount(),
    inventory: new Inventory()
}

export class Transaction extends Object {
    item: string;
    price: number;
    quantity: number;

    buyer: TransactionParticipant;
    seller: TransactionParticipant;
    timestamp: number;

    constructor(item: string, price: number, quantity: number, buyer: TransactionParticipant, seller: TransactionParticipant, timestamp: number)
    {
        super();

        this.item = item;
        this.price = price;
        this.quantity = quantity;
        this.buyer = buyer;
        this.seller = seller;
        this.timestamp = timestamp;
    }

}