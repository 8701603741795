import './App.css';
import { Citizen, CitizenComponent } from './sim/Entity/citizen';
import { Government, GovernmentComponent } from './sim/Entity/government';
import { Business, BusinessComponent } from './sim/Entity/businesses/business';
import useSimulation from './sim/useSimulation';
import ChoicePrompt from './sim/UI/ChoicePrompt';

import Notification, { NotificationType } from './sim/UI/Notification';
import Footer from './sim/UI/Footer';
import PopUpStatsMenu from './sim/UI/PopupStatsMenu';

function App() {

  const { citizens, government, businesses, duration, sim } = useSimulation();

  return (
    <div className='flex flex-col justify-between min-h-screen'>
      
      <ChoicePrompt title='Choose a New Citizen' sim={sim} />
      <PopUpStatsMenu bank={sim.bank} government={government as Government} />
      <div className='px-8 pt-20'>
        <div>
          <p className='px-1 mx-2 text-neutral-700 font-mono'>Citizens</p>
          <div className='flex flex-wrap' >
            {citizens.entities.map((c) => <CitizenComponent citizen={(c as unknown as Citizen)}/>)}
          </div>
        </div>
        <div>
          <p className='mx-2 text-neutral-700 font-mono px-1'>Businesses</p>
          <div className='flex flex-wrap'>
            {businesses.entities.map((b) => <BusinessComponent business={(b as unknown as Business)}/>)}
          </div>
        </div>
        <div>
          <p className='mx-2 text-neutral-700 font-mono px-1'>Government</p>
          <div className='flex flex-wrap'>
            <GovernmentComponent government={(government as unknown as Government)} />
          </div>
        </div>
      </div>
      <Footer duration_raw={duration} government={government as Government} bank={sim.bank} sim={sim} />
      <p className='absolute bottom-0 left-0 text-sm text-neutral-700'>X:{sim.root_scene.camera.position.x.toFixed(2)} Y:{sim.root_scene.camera.position.y.toFixed(2)} Z:{sim.root_scene.camera.position.z.toFixed(2)}</p>
    </div>
  );
}


//<Notification title='Good News!' message='You have been selected to be the next President. Good luck with that.' type={NotificationType.Info} /> 







export default App;

