import moment from "moment";
import { Citizen } from "../citizen";
import { Business, BusinessType } from "./business";
import { Time } from "../../time";
import { Bank } from "../../System/banking/bank";
import { Random } from "random";


export class Restaurant extends Business {

    restaurant: boolean = true;


    
    constructor(random: Random) {
        super("Restaurant", BusinessType.Restaurant, random);

        this.inventory.add("Breakfast", 1, 10);
        this.inventory.add("Lunch", 1, 10);
        this.inventory.add("Dinner", 1, 10);
    }


    eat(citizen: Citizen, time : Time, bank: Bank) : void
    {
        const hour = moment.duration(time.time * 1000).asHours() % 24;

        //if its 8 AM eat breakfast
        const breakfastTime : boolean = hour === 8;

        //if its noon eat lunch
        const lunchTime : boolean = hour === 12;

        //if its 6 PM eat dinner
        const dinnerTime : boolean = hour === 18;

        //perform the transactions
        if(breakfastTime){
            this.inventory.add("Breakfast", 1);
            this.processPurchase("Breakfast", 1, citizen, time.time, bank);
        }

        if(lunchTime){
            this.inventory.add("Lunch", 1);
            this.processPurchase("Lunch", 1, citizen, time.time, bank);
        }

        if(dinnerTime){
            this.inventory.add("Dinner", 1);
            this.processPurchase("Dinner", 1, citizen, time.time, bank);
        }
    }
}