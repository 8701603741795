import * as THREE from 'three';
import { Simulation } from '../sim';



export class RootScene
{

    sim : Simulation;
    renderer : THREE.WebGLRenderer;
    scene : THREE.Scene;
    camera : THREE.PerspectiveCamera;
    clock : THREE.Clock;

    constructor(sim : Simulation, clock : THREE.Clock){
        this.sim = sim;

        this.camera = new THREE.PerspectiveCamera( 40, window.innerWidth / window.innerHeight, 0.01, 100 );
        this.camera.position.x = 5;
        this.camera.position.z = 16;
        this.camera.position.y = 10;

        this.camera.rotation.x = -Math.PI / 5;

        this.scene = new THREE.Scene();

        this.renderer = new THREE.WebGLRenderer({ antialias: true });
        this.renderer.setClearColor("#d4d4d4");
        this.renderer.setSize( window.innerWidth, window.innerHeight);
        this.renderer.setAnimationLoop(this.render);

        this.clock = clock;

        const size = 100;
        const divisions = 100;

        const gridHelper = new THREE.GridHelper( size, divisions, 0x808080, 0xe6e6e6 );
        this.scene.add( gridHelper );

        const target = document.getElementById("sim-render");

        if(target === null) throw new Error("Could not find target element");

        target.appendChild( this.renderer.domElement );
    }

    addMesh(mesh: THREE.Mesh){
        this.scene.add(mesh);
    }

    addObject(object: THREE.Object3D){
        this.scene.add(object);
    }

    // animation
    render: XRFrameRequestCallback = (time: DOMHighResTimeStamp, frame: XRFrame) => {
        const deltaTime = this.clock.getDelta();

        const move_speed = 0.01;
        //TODO: update camera with input
        document.addEventListener('keydown', (event) => {
            if(event.keyCode == 37) {
                this.camera.position.x -= move_speed * deltaTime;
            }
            if(event.keyCode == 39) {
                this.camera.position.x += move_speed * deltaTime;
            }
            if(event.keyCode == 38) {
                this.camera.position.z -= move_speed * deltaTime;
            }
            if(event.keyCode == 40) {
                this.camera.position.z += move_speed * deltaTime;
            }
        });

        this.renderer.render( this.scene, this.camera );
    }
}