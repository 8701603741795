
const TwelvehoursInSeconds = 43200;
const OneHourInSeconds = 3600;

const OneMinuteInSeconds = 60;

const EightAM = OneHourInSeconds * 8;

export class Time
{
    private deltaTimeInternal: number = OneMinuteInSeconds;

    //ABOUT TIME
    //PosixTime only has 1 second resolution, but delta time can process fractions of a second by adding a decimal
    //posix time won't add the decimal but a system can add a decimal to deltaTime to represent fractions of a second
    //so that the other systems can account for fractions of a second
    //and so that we can log the fractional time in a human readable format

    private posixTime: number = EightAM; //8am on the first day

    get time(): number {
        return this.posixTime;
    }

    get deltaTime(): number {
        return this.deltaTimeInternal;
    }

    get deltaTimeInHours(): number {
        return this.deltaTimeInternal / OneHourInSeconds;
    }

    step(): void {
        this.posixTime += this.deltaTimeInternal;
    }

    setDeltaTime(deltaTime: number): void {
        this.deltaTimeInternal = deltaTime;
    }

    getHour(): number {
        return Math.floor(this.posixTime / OneHourInSeconds) % 24;
    }

    isBetweenHours(startHour: number, endHour: number): boolean {
        const hour = this.getHour();
        return hour >= startHour && hour < endHour;
    }


}