

//These values initialized from median median wage from the beauru of labor statistics

import moment from "moment";
import { DiffDisplay } from "../../util/diff";
import { World } from "miniplex";
import { Citizen } from "../../Entity/citizen";
import { Time } from "../../time";
import { Bank } from "../../System/banking/bank";
import { Business } from "../../Entity/businesses/business";
import { Government } from "../../Entity/government";
import { StatDropDown } from "../../UI/InfoCard";
import { CurrencyDisplay } from "../../UI/Units";

//https://www.bls.gov/oes/current/oes_nat.htm
export const occupation_list : { [key : string] : { median : number }} = {
    "Doctor" : {
        median: 109.22
    },
    "Lawyer" : {
        median: 65.26
    },
    "Construction Worker" : {
        median: 24.31
    },
    /*"Teacher" : {
        median: 28.17
    },
    "Engineer" : {
        median: 40.24
    },
    "Programmer" : {
        median: 54.90
    },
    "Accountant" : {
        median: 37.50
    },
    "Scientist" : {
        median: 40.27
    },
    "Artist" : {
        median: 24.59
    },
    "Athlete" : {
        median: 24.60
    },
    "Musician" : {
        median: 36.01
    },
    "Actor" : {
        median: 17.94
    },
    "Writer" : {
        median: 36.10
    },
    "Chef" : {
        median: 27.17
    },
    "Police Officer" : {
        median: 31.64
    },
    "Firefighter" : {
        median: 24.03
    },
    "Pilot" : {
        median: 77.19
    },
    "Nurse" : {
        median: 39.05
    },
    "Dentist" : {
        median: 76.70
    },
    "Psychologist" : {
        median: 41.02
    },
    "Pharmacist" : {
        median: 63.82
    },
    "Veterinarian" : {
        median: 49.64
    },
    "Plumber" : {
        median: 28.89
    },
    "Electrician" : {
        median: 28.96
    },
    "Mechanic" : {
        median: 26.14
    },
    "Farmer" : {
        median: 36.42
    },
    "Truck Driver" : {
        median: 22.26
    },
    "Cashier" : {
        median: 13.58
    },
    "Waiter" : {
        median: 14.00
    },
    "Janitor" : {
        median: 15.38
    },
    "Housekeeper" : {
        median: 14.40
    },
    "Security Guard" : {
        median: 16.71
    },
    "Bus Driver" : {
        median: 24.47
    },
    "Mail Carrier" : {
        median: 26.08
    },
    "Hairdresser" : {
        median: 16.01
    },
    "Flight Attendant" : {
        median: 23.85
    },
    "Bartender" : {
        median: 14.12
    },
    "Librarian" : {
        median: 24.62
    },
    "Architect" : {
        median: 39.76
    },
    "Secretary" : {
        median: 21.19
    },
    "Receptionist" : {
        median: 16.33
    },
    "Judge" : {
        median: 54.61
    },
    "Politician" : {
        median: 61.55
    },
    "CEO" : {
        median: 180.70
    },
    "Manager" : {
        median: 48.00
    },
    "Banker" : {
        median: 40.24
    },
    "Real Estate Agent" : {
        median: 45.99
    },
    "Stock Broker" : {
        median: 61.67
    },
    "Journalist" : {
        median: 20.82
    },
    "Photographer" : {
        median: 17.44
    },
    "Dancer" : {
        median: 14.25
    },
    "Model" : {
        median: 14.25
    },*/
};

export const occupationsArray = Object.keys(occupation_list).map((key) => {
    return {
        name: key,
        median: occupation_list[key].median
    };
});

enum PaydayType {
    Weekly = 7,
    BiWeekly = 14,
    Monthly = 30
}

export class Occupation extends Object {
    name: string;
    median: number;
    
    //the amount of money the company owes to the worker
    accounts_payable: {
        balance: number,
        diff_this_time_step: number
    }

    payday_type: PaydayType;

    businessID: number | undefined;

    workStartHour: number = 8;
    workEndHour: number = 17;

    constructor(world : World)
    {
        super();
        const occupation = randomOccupation();

        this.name = occupation.name;
        this.median = occupation.median;
        this.accounts_payable = {
            balance: 0,
            diff_this_time_step: 0
        };

        const businesses = world.with("business").entities;

        this.businessID = world.id(businesses[Math.floor(Math.random() * businesses.length)]);

        this.payday_type = PaydayType.BiWeekly;
    }

    isPayday(posixTime: number) : boolean
    {
        const day = moment.duration(posixTime * 1000).asDays() % this.payday_type;
        const hour = moment.duration(posixTime * 1000).asHours() % 24;
        //for now payout at midnight
        return day === 0 && hour === 0;
    }

    update(time: Time, worker : Citizen, business : Business, government : Government, bank: Bank){
        if(time.isBetweenHours(this.workStartHour, this.workEndHour)){
            this.accounts_payable.balance += this.median * time.deltaTimeInHours;
            //TODO: move diff_this_time_step to a setter on the account
            this.accounts_payable.diff_this_time_step = this.median * time.deltaTimeInHours;
        }else{
            this.accounts_payable.diff_this_time_step = 0;
        }

        if(this.isPayday(time.time)){
            const grossPay = this.accounts_payable.balance;

            const payout = government.collectTaxes(grossPay);

            const payTransactionName = `Payday for ${this.name}`;

            worker.inventory.add(payTransactionName, 1);
            
            bank.performTransaction(
                payTransactionName,
                this.accounts_payable.balance,
                1,
                business,
                worker,
                time.time
            );

            //checkingAccount.balance += payout;
            worker.checkingAccount.diff_this_time_step = payout;

            this.accounts_payable.balance = 0;
            
        }else{
            worker.checkingAccount.diff_this_time_step = 0;
        }
    }


}

export function OccupationComponent(props : {occupation: Occupation}) {
    return(
        <StatDropDown title="Occupation" >
            <div >
                <p className='text-gray-600 text-xs'>Wages Owed</p>
                <div className="flex flex-row">
                    <CurrencyDisplay value={props.occupation.accounts_payable.balance} />
                    <DiffDisplay diff={props.occupation.accounts_payable.diff_this_time_step}/>
                </div>
            </div>
        </StatDropDown>
    )
}


function randomOccupation() : { name: string, median: number }
{
    return occupationsArray[Math.floor(Math.random() * occupationsArray.length)];
}