import { useEntities } from "miniplex-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Simulation } from "./sim";

const sim = new Simulation(
    127649817634, //seed
);

const allEntitiesQuery = sim.world.with('citizen');
const governmentEntityQuery = sim.world.with('government');
const allBusinessesQuery = sim.world.with('business');

export default function useSimulation() {

    const [duration, setDuration] = useState<number>(0);

    useEffect(() => {
        sim.start(setDuration);
    },[]);

    const citizens = useEntities(allEntitiesQuery);

    const [government] = useEntities(governmentEntityQuery);

    const businesses = useEntities(allBusinessesQuery); 

    return {
        citizens,
        government,
        businesses,
        duration,
        sim,
    };
}
