import { World } from "miniplex";
import { Citizen } from "./Entity/citizen";

import occupation_system from "./System/occupation_system";
import { Government } from "./Entity/government";
import rent_system from "./System/rent_system";
import food_system from "./System/daily_life/food_system";
import { Restaurant } from "./Entity/businesses/restaurant";
import water_system from "./System/water_system";
import random, { Random } from "random";
import { Time } from "./time";
import { System } from "./System/system";
import { Bank } from "./System/banking/bank";
import { Water } from "./Component/citizen/water";

import { RootScene } from "./3D/RootScene";
import { Business } from "./Entity/businesses/business";
import { Clock, Object3D, Vector3 } from "three";
import { Nav } from "./Component/nav";
import { nav_system } from "./System/3D/Nav";




type WorldEntity =  {
    citizen?: boolean;
    government?: boolean;
    residence?: boolean;
    business?: boolean;
    restaurant?: boolean;
    water?: Water;
    nav?: Nav;
    transform?: Object3D;
}

export enum SimulationSpeed {
    Standard = 1,
    Fast = 10
}

export class Simulation {
    
    world: World<WorldEntity> = new World<WorldEntity>();

    sim_time : Time;
    render_time : Clock;

    systems: Array<System> = [
        occupation_system,
        rent_system,
        food_system,
        water_system,
        nav_system
        //renderer3DSystem - for now handled by the animation loop
    ];

    bank : Bank = new Bank();

    residences_by_id: Array<number> = [];

    updateIntervalMS: number;
    steps_per_interval: number;

    is_processing_step: boolean = false;

    paused: boolean = false;

    rng: Random;

    root_scene: RootScene;

    constructor(seed: number,)
    {
        this.updateIntervalMS = 100;

        this.steps_per_interval = SimulationSpeed.Standard;

        this.sim_time = new Time();
        this.render_time = new Clock();

        this.rng = random.clone(seed);

        const government = new Government(17);

        this.world.add(government);

        this.root_scene = new RootScene(this, this.render_time);

        this.pause = this.pause.bind(this);

        this.resume = this.resume.bind(this);

        this.isPaused = this.isPaused.bind(this);

        this.addBusiness(new Restaurant(this.rng));
    }

    public addCitizen(citizen: Citizen){
        this.world.add(citizen);
        this.root_scene.addObject(citizen.transform);
    }

    public getNewCitizen() : Citizen
    {
        return new Citizen(this.world, this.rng);
    }

    public addBusiness(business: Business )
    {
        this.world.add(business);
        this.root_scene.addMesh(business.mesh);
    }

    public isPaused() : boolean
    {
        return this.paused;
    }

    public pause()
    {
        this.paused = true;
    }

    public resume()
    {
        this.paused = false;
    }

    public setSpeed(speed: SimulationSpeed)
    {
        console.log(`Switching to speed: ${this.steps_per_interval}`);
        this.steps_per_interval = speed;
    }

    public getSpeed() : SimulationSpeed
    {
        return this.steps_per_interval;
    }

    async start(setDuration : React.Dispatch<React.SetStateAction<number>>){
        
        setInterval(async () => {
            if(!this.is_processing_step){
                for(let i = 0; i < this.steps_per_interval; i++){
                    const currentDuaration = await this.step();
                    setDuration(currentDuaration);
                }
            }
        }, this.updateIntervalMS);
        
    }


    //TODO: make this method async and wait for the systems to finish
    //if the update is taking too long, we can just skip step() until this step is finished
    async step() : Promise<number>
    {
        if(this.paused){
            return this.sim_time.time;
        }

        this.is_processing_step = true;
        this.systems.forEach((system) => {
            system(this);
        });

        this.sim_time.step();

        this.is_processing_step = false;

        return this.sim_time.time;


    }
}