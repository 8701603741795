import { Vector3 } from "three";



export class Nav extends Object {
    target: Vector3;
    lerp_target: Vector3;
    speed: number;
    progress: number; //value from 0 to 1 representing the progress of the lerp
    lerp_duration: number; //duration of the lerp in seconds
    lerp_progress: number; //current progress of duration in seconds

    constructor(
        speed: number,
    ) {
        super();

        this.speed = speed;
        this.target = new Vector3();
        this.lerp_duration = 0;
        this.progress = 0;
        this.lerp_target = new Vector3();
        this.lerp_progress = 0;
    }

    setTarget(target: Vector3) {
        this.target.copy(target);
    }
}