
enum DiffType {
    Positive,
    gray,
    Negative
}

const DiffTypeToStyleClass = {
    [DiffType.Positive]: 'text-green-700 ',
    [DiffType.gray]: 'text-gray-700 ',
    [DiffType.Negative]: 'text-red-700 '
}

export function DiffDisplay(props: {diff: number}) {

    const diffType = getDiffTypeFromValue(props.diff);
    const style_class = DiffTypeToStyleClass[diffType];

    return(
        <p className={style_class + 'text-xs font-mono'}>
            {props.diff.toFixed(2)}
        </p>
    );
}

function getDiffTypeFromValue(value : number)
{
    if(value === 0) return DiffType.gray;

    return value > 0 ? DiffType.Positive : DiffType.Negative;
}

