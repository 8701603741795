import { FederalReserve, FederalReserveComponent } from "../Component/government/federalReserve";
import { Tax, TaxComponent } from "../Component/government/tax";
import { Card } from "../UI/Card";



export class Government extends Object {

    federalReserve : FederalReserve;

    tax : Tax;

    government : boolean = true;

    constructor(federalTaxRate: number)
    {
        super();

        this.federalReserve = new FederalReserve();
        this.tax = new Tax(federalTaxRate);
    }

    collectTaxes(income: number) : number
    {
        const [tax, netIncome] =  this.tax.computeTax(income);

        this.federalReserve.deposit(tax);

        return netIncome;
    }
}

export function GovernmentComponent(props : {government: Government}) {
    return (
        <div className="mx-2">
            <Card title="Government">
                <div className="mx-2 bg-white">
                    <FederalReserveComponent federalReserve={props.government.federalReserve}/>
                    <TaxComponent tax={props.government.tax}/>
                </div>
            </Card>
        </div>
    );
}