import { Government } from "../Entity/government";
import { Bank } from "../System/banking/bank";
import { MetricDisplay, SIUnit } from "./Units";
import { useKeyDown } from "./hooks/useKey";

export default function PopUpStatsMenu(props : {bank: Bank, government: Government}): JSX.Element
{
    const isTabDown = useKeyDown('Tab');

    return(
        <div className={`absolute flex items-center justify-center top-0 w-screen h-screen z-50 filter backdrop-blur-md ${isTabDown ? '' : 'hidden'}  p-1`}>
            <div className="bg-neutral-400 flex flex-col w-96 h-96">
                <p className="bg-neutral-300 w-full text-center font-mono font-bold text-neutral-600">STATS</p>
                <div className='flex flex-row   space-x-4 items-center justify-center '>
                    <MetricDisplay title='GDP' value={props.bank.GDP} unit={SIUnit.USD} />
                    <MetricDisplay title='Federal Reserve Balance' value={props.government.federalReserve.balance} unit={SIUnit.USD} />
                </div>
            </div>
        </div>
    )
    
}