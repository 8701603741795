import { Transaction, TransactionError, TransactionParticipant } from "./transaction";

let instance: Bank | undefined = undefined;

export class Bank {

    transaction_history: Array<Transaction>;

    GDP : number = 0;

    constructor() {
        this.transaction_history = [];
    }

    public static getInstance() {
        if (instance === undefined) {
            instance = new Bank();
        }

        return instance;
    }

    public performTransactionWithInventory(item: string, quantity: number, buyer: TransactionParticipant, seller: TransactionParticipant, posixTime: number, adds_to_gdp?: boolean) {
        const inventory_item = seller.inventory.inventory.get(item);
        const price = inventory_item?.price;

        if (price === undefined) {
            throw new TransactionError("Item does not have a price");
        }

        this.performTransaction(item, price, quantity, buyer, seller, posixTime, adds_to_gdp);
    }
        

    public performTransaction(item: string, price: number, quantity: number, buyer: TransactionParticipant, seller: TransactionParticipant, posixTime: number , adds_to_gdp?: boolean) {

        TransactionError.validateTransaction(item, price, quantity, buyer, seller);

        buyer.checkingAccount.balance -= price;
        seller.checkingAccount.balance += price;

        buyer.checkingAccount.diff_this_time_step -= price;
        seller.checkingAccount.diff_this_time_step += price;

        buyer.inventory.add(item, quantity);
        seller.inventory.remove(item, quantity);

        this.transaction_history.push(new Transaction(item, price, quantity, buyer, seller, posixTime));

        if (adds_to_gdp) this.GDP += (price * quantity);
    }
}