import { SIUnit, UnitsDisplay } from "./Units";



export function SlimProgessBar(props : {progress: number, color: string}) {

    const progress = Math.min(100, props.progress);
    return (
        <div className="w-full h-2 bg-gray-200 overflow-hidden border-b border-gray-700">
            <div className={`h-full bg-${props.color}-200`} style={{width: `${progress}%`}}></div>
        </div>
    );
}

export function ProgressDisplay(props : {label : string, color: string, value: number, max : number, units?: SIUnit}) {
    const progress = (props.value / props.max) * 100;

    const value_display = props.units ? <UnitsDisplay value={props.value} unit={props.units}/> : <TinyValue value={props.value}/>;

    return (
        <div className="my-2">
             <div className='flex flex-row'>
                <p className='text-gray-600 text-xs'>{props.label}</p>
                {value_display}
            </div> 
            <SlimProgessBar progress={progress} color={props.color} />
            
        </div>
    );

}

function TinyValue(props : {value: number}) {
    return(
        <p className='text-gray-900 font-mono text-xs pl-2'>{ props.value.toFixed(2) }</p>
    );
}