import { World } from "miniplex";
import moment from "moment";
import { Bank } from "./banking/bank";
import { DefaultParticipant } from "./banking/transaction";
import { Time } from "../time";
import { Citizen } from "../Entity/citizen";
import { Business } from "../Entity/businesses/business";
import { Simulation } from "../sim";
import { Government } from "../Entity/government";


export default function occupation_system(sim : Simulation)
{
    const { world, sim_time, bank } = sim;

    const workers = world.with("citizen");

    const [government] = world.with("government");

    for (var worker of workers) {
        const { occupation } : Citizen = worker as Citizen;

        const business : Business = world.entity(occupation.businessID ?? 0) as Business;

        occupation.update(sim_time, worker as Citizen, business, government as Government, bank );
    }
}

function isWorkTime(posixTime: number) : boolean
{
    const hour = moment.duration(posixTime * 1000).asHours() % 24;
    return hour >= 8 && hour < 17;
}