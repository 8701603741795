

//implement a simple 3D navmesh that just uses manhattan distance and traversal of the grid
//

import { Vector3 } from "three";
import { Simulation } from "../../sim";
import { Nav } from "../../Component/nav";

const DISTANCE_THRESHOLD = 0.01;

//this will be used for pathfinding for the citizens
export function nav_system(sim: Simulation) {
    const citizens = sim.world.with("nav", "transform");
    const delta_time = sim.render_time.getDelta();
    
    for( const entity of citizens ) {
        const { target} = entity.nav;
        const { position } = entity.transform;
        

        //We're already here!
        if(position.distanceTo(target) < DISTANCE_THRESHOLD) {
            continue;
        }
    
        /*
        //Check if we need to find a new lerp target
        if(position.distanceTo(entity.nav.lerp_target) < DISTANCE_THRESHOLD) {
            const next_lerp_target = compute_next_lerp_target(position, target);
            entity.nav.lerp_target.copy(next_lerp_target);
            entity.nav.lerp_progress = 0;
            //compute the duration of the lerp
            const speed = entity.nav.speed;
            const distance = position.distanceTo(next_lerp_target);
            entity.nav.lerp_duration = distance / speed;
        }*/

        //we're still navving! moving between sub targets
        const next_frame_position = compute_next_frame_position(position, target, 0.1);
        position.copy(next_frame_position);
    }

}


//once we've reached the current sub target we need to find the next segment in the graph we need to traverse
function compute_next_frame_position(position: Vector3, target: Vector3, speed: number): Vector3
{
    const a_star_direction = new Vector3();
    a_star_direction.subVectors(target, position).normalize();

    //compute manhattan distance move
    //move in the x direction first
    if(Math.abs(a_star_direction.x) > 0) {
        const sign = Math.sign(a_star_direction.x);
        return new Vector3(position.x + (sign * speed), position.y, position.z);
    }
    //move in the negative z direction
    else if(Math.abs(a_star_direction.z) > 0) {
        const sign = Math.sign(a_star_direction.z);
        return new Vector3(position.x, position.y, position.z + (sign * speed));
    }
    //move in the y direction
    else if(Math.abs(a_star_direction.y) > 0) {
        const sign = Math.sign(a_star_direction.y);
        return new Vector3(position.x, position.y + (sign * speed), position.z);
    }
    //we should never reach this point
    else {
        return target;
    }


}

/*
function compute_next_frame_position(nav: Nav, position: Vector3, delta_time: number): Vector3
{
    var { lerp_target, lerp_duration, lerp_progress   } = nav;


    const next_frame_position = new Vector3();
    lerp_progress = Math.min(lerp_progress + delta_time, lerp_duration);
    const lerp_percentage = lerp_progress / lerp_duration;
    return next_frame_position.lerpVectors(position, lerp_target, lerp_percentage);
}*/