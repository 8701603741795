export class Tax extends Object {

    federal_rate: number;

    constructor(federal_rate: number) {
        super();

        this.federal_rate = 17;
    }

    computeTax(income: number) : [number, number]
    {
        const tax = income * this.federal_rate / 100;
        const income_after_tax = income - tax;
        return [tax, income_after_tax];
    }


}

export function TaxComponent(props: { tax: Tax }) {
    return (
        <div className='m-2' key={"government"}>
            <div className="p-2">
                <p className='text-gray-700 font-semibold text-sm'>Federal Tax Rate</p>
                <p className='text-blue-700 text-xs font-mono'>
                    <span className="font-bold text-xs">% </span>
                    { props.tax.federal_rate.toFixed(2) }
                </p>
            </div>
        </div>
    );
}