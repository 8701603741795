
import { World } from "miniplex";
import { Time } from "../../time";
import { Restaurant } from "../../Entity/businesses/restaurant";
import { Simulation } from "../../sim";
import { Citizen } from "../../Entity/citizen";


export default function food_system(sim : Simulation)
{
    const { world, sim_time, bank } = sim;


    const citizens = world.with("citizen");

    //TODO: get people to eat at restaurants and shop for groceries

    const [restaurant_untyped] = world.with("restaurant");

    const restaurant = restaurant_untyped as Restaurant;

    if(!restaurant) {
        return;
    }

    for(const entity of citizens){

        restaurant.eat(entity as Citizen, sim_time, bank);
    }
}