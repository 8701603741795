const USDCurrencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const NumberWithCommaFormatter = new Intl.NumberFormat('en-US', { 
    style: 'decimal', 
    maximumFractionDigits: 2, 
    minimumFractionDigits: 2,
    useGrouping: true,
    notation: 'compact',
});

function formatUSD(value: number): string {
  return USDCurrencyFormatter.format(value);
}

function formatNumber(value: number): string {
    return NumberWithCommaFormatter.format(value);
}


export enum SIUnit {
    Liters = "Liters",
    USD = "USD",
}

export function UnitsDisplay(props: {value : number, unit: SIUnit}) {

    const unitSuffix = props.unit.toString();

    return (
        <p className="text-neutral-600 text-xs px-2">
            <span className="font-semibold pr-1">{props.value.toFixed(2)}</span>
            {unitSuffix}
        </p>
    );
}

export function CurrencyDisplay(props: {value : number}) {
    return (
        <p className="">
            <span className="text-neutral-600 text-sm font-mono">{formatUSD(props.value)}</span>
        </p>
    );
}

export function MetricDisplay(props : { title: string, value: number, unit: SIUnit}) {
    return (
        <div className='h-full filter flex-col items-start' key={"government"}>
            <p className=" text-lg font-mono text-white px-1 text-right flex flex-row items-end justify-end">
                {formatNumber(props.value)}
                <span className="text-xs text-neutral-300 items-center flex px-0.5 pl-1">{" " + props.unit}</span>
            </p>
            <div className="flex flex-row justify-end w-full bg-neutral-600">
                <p className='text-neutral-300 font-mono text-xs border-t font-bold px-1 flex-grow text-right'>{props.title}</p>
            </div>
        </div> 
    )
}
