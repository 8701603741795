
export function Card(props : { children: any, title?: string, subtitle?: string }) {
    return (
        <div className=' aspect-square w-44 bg-opacity-10 backdrop-brightness-105 filter backdrop-blur-sm h-min'>
            <div className=" flex items-start w-full flex-wrap">
                <p className='text-white font-mono px-1 bg-neutral-500 text-sm font-semibold'>{props.title ?? ""}</p>
                <p className='text-neutral-500 text-xs bg-white px-2'>{props.subtitle ?? ""}</p>
            </div>
            <div className="h-min">
                {props.children}
            </div>
        </div>
    );
}

